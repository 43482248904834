
<i18n>
{
	"ru": {
		"guest": "|{n} гость|{n} гостя|{n} гостей",
		"occupiedIn": "Заезд",
		"occupiedOut": "Отъезд",
		"startSearch": "Смотреть цены",
    "cityTitle": "Выбор направления",
    "label": "Курорт, город или адрес",
    "forExample": "Например",
    "filters": "Фильтры",
    "map": "Карта",
    "petersburg": "Санкт-Петербург",
    "crimea": "Крым",
    "kazan": "Казань",
    "kislovodsk": "Кисловодск",
    "moscow": "Москва",
    "sochi": "Сочи",
    "sheregesh": "Шерегеш",
    "abkhazia": "Абхазия",
    "minsk":"Минск",
    "dagestan": "Дагестан",
    "arkhyz": "Архыз",
    "baikal": "Байкал",
    "karelia": "Карелия"
	},
  "en": {
		"guest": "|{n} guest|{n} guests|{n} guests",
		"moreThanVariants": "100 thousand options for trips to Russia and abroad",
		"occupiedIn": "Check in",
		"occupiedOut": "Check out",
		"startSearch": "See prices",
    "cityTitle": "Choice location",
    "forExample": "For example",
    "filters": "Filters",
    "map": "Map",
    "petersburg": "St. Petersburg",
    "crimea": "Crimea",
    "kazan": "Kazan",
    "kislovodsk": "Kislovodsk",
    "abkhazia": "Abkhazia",
    "moscow": "Moscow",
    "sochi": "Sochi",
    "sheregesh": "Sheregesh",
    "minsk":"Minsk",
    "dagestan": "Dagestan",
    "arkhyz": "Arkhyz",
    "baikal": "Baikal",
    "karelia": "Karelia"
	}
}
</i18n>
<template>
  <form
    class="sc-searchwidget"
    :class="[{}, `sc-searchwidget_${viewPort}`]"
    v-observe-visibility="{
      callback: (isVisible, entry) => visibilityChanged(isVisible, entry),
      throttle: 1,
      intersection: {
        threshold: 0.001,
      },
    }"
  >
    <template v-if="viewPort === 'desktop'">
      <Suggestions
        ref="baseSuggestions"
        class="sc-searchwidget__suggestions"
        :locationInsert="locationPreselect ? locationPreselect : locationInsert"
        @applyLocation="handlerLocation"
        @listSuggestions="listSuggestions = $event"
      />
    </template>
    <template v-else>
      <Modal ref="baseModalRefCity" :title="$t('cityTitle')" @close="closeSuggestions()">
        <SuggestionsModal
          ref="baseSuggestions"
          class="sc-searchwidget__suggestions"
          :locationInsert="locationInsert"
          :locationData="locationData"
          @applyLocation="handlerLocation"
          @listSuggestions="listSuggestions = $event"
          @choice="closeSuggestions()"
        />
      </Modal>
      <div
        class="sc-searchwidget__suggestions-modal"
        :class="{
          'sc-searchwidget__suggestions-modal__fill':
            locationData && locationData.title,
        }"
        @click="showSuggestions()"
      >
        {{
          locationData && locationData.title
            ? locationData.title
            : $t("label")
        }}
      </div>
    </template>

    <DatePickerModal
      ref="calendar"
      v-model="date"
      v-if="viewPort === 'mobile'"
      :fill-mode="fillMode"
      @reset="handleReset"
      @apply="doSearch(null, 'calendar')"
    />

    <div
      v-click-outside="clickOutside"
      :style="{ zIndex: 1 }"
      class="sc-searchwidget__occupied"
      :class="{
        'sc-searchwidget__occupied-mobile': viewPort === 'mobile',
      }"
    >
      <div
        data-cy="checkIn"
        class="sc-searchwidget__occupied_in"
        :class="{
          'sc-searchwidget__occupied_in-fill': dateViews.in,
          'sc-searchwidget__occupied_in-active': activeIn || activeFieldIn,
          'sc-searchwidget__occupied_in-active-choice':
            (activeIn || activeFieldIn) && dateViews.in,
        }"
        @click="checkInOpen()"
      >
        <div class="title" :class="{ active: dateViews.in }">
          {{ $t("occupiedIn") }}
        </div>
        <div class="date">
          {{ dateViews.in ? format(dateViews.in) : "" }}
        </div>
      </div>
      <div
        data-cy="checkOut"
        class="sc-searchwidget__occupied_out"
        :class="{
          'sc-searchwidget__occupied_out-fill': dateViews.out,
          'sc-searchwidget__occupied_out-active': activeOut || activeFieldOut,
          'sc-searchwidget__occupied_in-active-choice':
            (activeOut || activeFieldOut) && dateViews.out,
        }"
        @click="checkOutOpen()"
      >
        <div class="title" :class="{ active: dateViews.out }">
          {{ $t("occupiedOut") }}
        </div>
        <div class="date">
          {{ dateViews.out ? format(dateViews.out) : "" }}
        </div>
      </div>
      <div class="calendar-in" v-if="viewPort === 'desktop'">
        <DatePicker
          ref="calendar"
          v-model="date"
          :fill-mode="fillMode"
          :date-views.sync="dateViews"
          :show-header="!dateViews.in || !dateViews.out"
          @visibleCalendar="visibleCalendar = $event"
          @reset="handleReset"
          @dateType="dateType"
        />
      </div>
    </div>
    <SelectGuests
      class="sc-searchwidget__guest"
      v-show="viewGuests || viewPort === 'desktop'"
      ref="guests"
      :guestsInsert="guestsInsert"
      :isMobile="viewPort === 'mobile' ? true : false"
      :isMain="true"
      :isPlatform="isPlatform"
      @clickGuests="clickGuests"
      @applyGuests="handlerGuests"
      @cntGuests="showGuests"
      @clickDone="doSearch"
    />
    <button
      type="submit"
      class="sc-searchwidget__btn"
      :class="{
        loading: isBtnDisabled || listSuggestions.isLoading,
        'sc-searchwidget__btn-no-guests': !viewGuests && viewPort === 'mobile'
      }"
      :disabled="isBtnDisabled || listSuggestions.isLoading"
      @click.prevent.stop="doSearch(null, 'search')"
    >
      {{ $t("startSearch") }}
    </button>
    <div class="sc-searchwidget__select-location" v-if="viewPort === 'desktop'">
      <span class="title">{{ $t("forExample") }}</span>
      <template v-for="(city, index) in preselected_cities">
        <span :key="index" class="val" @click="preselectCity(city)">
          {{ city.title }}
        </span>
      </template>
    </div>
    <transition name="slide">
      <div
        class="sc-searchwidget-fixed"
        v-if="isFixedPanel && isFixed && viewPortFixed"
      >
        <div class="sc-searchwidget-fixed__wrap">
          <button
            class="sc-searchwidget-fixed__button"
            :class="{
              'sc-searchwidget-fixed__button_fill': date && date.in && date.out,
            }"
            @click.prevent.stop="doSearch('calendar')"
          >
            <span v-if="date && date.in && date.out"
              >{{ format(date.in, true) }}&nbsp;—&nbsp;{{
                format(date.out, true)
              }}</span
            >
            <span v-else
              >{{ $t("occupiedIn") }}&nbsp;—&nbsp;{{ $t("occupiedOut") }}</span
            >
          </button>

          <button
            class="sc-searchwidget-fixed__button"
            :class="{
              'sc-searchwidget-fixed__button_fill': guestsCnt > 1,
            }"
            @click.prevent.stop="doSearch('guests')"
          >
            {{ $tc("guest", guestsCnt) }}
          </button>
          <button
            class="sc-searchwidget-fixed__button"
            @click.prevent.stop="doSearch('filter')"
          >
            {{ $t("filters") }}
          </button>
        </div>
      </div>
    </transition>
    <transition name="map" v-if="isPlatform">
      <div class="to-map" v-if="isFixedPanel && isFixed && viewPortFixed">
        <button class="to-map__btn" @click.prevent.stop="clickToMap"> {{ $t("map") }} </button>
      </div>
    </transition>
  </form>
</template>

<script>
import DatePicker from "@sutochno/datepickerext";
import DatePickerModal from "./DatePickerModal.vue";
import { SuggestionsModal } from "@sutochno/suggestions";
import { Suggestions } from "@sutochno/suggestions";
import SelectGuests from "@/selectguests";
import vClickOutside from "@sutochno/v-click-outside";
import { ObserveVisibility } from "vue-observe-visibility";
import VueCookies from "vue-cookies";
import eventsDispatcher from "./eventsDispatcher.js";

const Modal = () => import('@sutochno/modal');

import _ from 'lodash';

export default {
  name: "SearchWidget",
  directives: {
    "click-outside": vClickOutside,
    "observe-visibility": ObserveVisibility,
  },
  props: {
    datesInsert: {
      type: Object,
    },
    guestsInsert: {
      type: Number,
      default: 0,
    },
    locationInsert: {
      type: Object,
    },
    isPlatform: {
      type: Boolean,
      default: false,
    },
    isFixedPanel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatePicker,
    DatePickerModal,
    Suggestions,
    SuggestionsModal,
    SelectGuests,
    Modal,
  },
  provide: function () {
    return {
      isPlatform: this.isPlatform,
    };
  },
  data() {
    return {
      isOpenMap: false,
      viewDate: true,
      visibleCalendar: false,
      activeFieldIn: false,
      activeFieldOut: false,
      date: {
        in: null,
        out: null,
      },
      dateViews: {
        in: null,
        out: null,
      },
      locationData: null,
      guestsData: {
        adults: 2,
        childrens: [],
        pets: {
          value: false,
          description: ""
        }
      },
      isBtnDisabled: false,
      listSuggestions: {
        isLoading: false,
        isError: false,
      },
      viewPort: "desktop",
      viewPortFixed: false,
      isFixed: false,
      locationPreselect: null,
      applyWatchLocation: false,
      viewGuests: false,
      preselected_cities: [
        {
          id: 397367,
          title: this.$t("petersburg"),
          type: "city",
          bbox: {
            SW: {
              lat: 59.744311,
              lng: 30.043373,
            },
            NE: {
              lat: 60.090935,
              lng: 30.566426,
            },
          },
        },
        {
          id: 397366,
          title: this.$t("moscow"),
          type: "city",
          bbox: {
            SW: {
              lat: 55.49133,
              lng: 37.326051,
            },
            NE: {
              lat: 55.957565,
              lng: 37.96779,
            },
          },
        },
        {
          id: 287390,
          title: this.$t("sochi"),
          type: "city",
          bbox: {
            SW: {
              lat: 43.38669197168201,
              lng: 39.188110968098044,
            },
            NE: {
              lat: 44.00668198708445,
              lng: 40.290568778291345,
            },
          },
        },
        /* {
          id: 411389,
          title: this.$t("baikal"),
          type: "city",
          bbox: {
            SW: { lat: 51.1869, lng: 103.3837 },
            NE: { lat: 56.0172, lng: 110.3931 },
          },
        }, */
        {
          id: 398612,
          title: this.$t("minsk"),
          type: "city",
          bbox: {
            SW: {
              lat: 53.80038928698426,
              lng: 27.368931835937094,
            },
            NE: {
              lat: 53.98457211750567,
              lng: 27.75099115624918,
            },
          },
        },
        {
           id: 281471,
           title: this.$t("kazan"),
           type: "city",
           bbox: {
             SW: {
               lat: 55.747455,
               lng: 49.048392,
             },
             NE: {
               lat: 55.839633,
               lng: 49.238044,
             },
          },
        },
        {
           id: 19815,
           title: this.$t("dagestan"),
           type: "region",
           bbox: {
             SW: {
               lat: 41.185996,
               lng: 45.104365,
             },
             NE: {
               lat: 44.992524,
               lng: 48.796486,
             },
           },
        },
        {
           id: 289700,
           title: this.$t("kislovodsk"), // Кисловодск
           type: "city",
           bbox: {
             SW: {
               lat: 43.843869,
               lng: 42.659879,
             },
             NE: {
               lat: 43.960004,
               lng: 42.828601,
             },
           },
        },
        {
          id: 242,
          title: this.$t("abkhazia"),
          type: "country",
          bbox: {
            SW: {
              lat: 42.326447,
              lng: 39.828569,
            },
            NE: {
            lat: 43.586419,
              lng: 42.132631,
            },
          },
        },
        /* {
          id: 398347,
          title: this.$t("sheregesh"),
          type: "city",
          bbox: {
            SW: { lat: 52.878508, lng: 87.921504 },
            NE: { lat: 52.965067, lng: 88.019384 },
          },
        },
        {
          id: 410738,
          title: this.$t("arkhyz"),
          type: "city",
          bbox: {
            SW: { lat: 43.509017, lng: 41.169035 },
            NE: { lat: 43.584127, lng: 41.381406 },
          },
        },
        {
          id: 19820,
          title: this.$t("karelia"),
          type: "region",
          bbox: {
            SW: { lat: 60.679619, lng: 29.306117 },
            NE: { lat: 66.668641, lng: 37.946517 },
          },
        }, */
      ],
    };
  },
  mounted() {
    this.handleWindowResize();
    this.$nextTick(function () {
      window.addEventListener("resize", this.handleWindowResize);
      setTimeout(() => {
        this.handleWindowResize();
      }, 2000);
      this.initialSuggestions();
      this.initialDatesInsert();

      if(this.isPlatform && this.viewPort !== 'mobile'){
        this.checkInOpen();
      }

      this.$nextTick(() => {
        this.applyWatchLocation = true
      })
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  watch: {
    datesInsert: {
      deep: true,
      handler() {
        this.initialDatesInsert();
      },
    },
    "dateViews.in"(val) {
      if (val) {
        this.activeFieldIn = false;
      }
    },
    "dateViews.out"(val) {
      if(!!val && this.isDates(this.dateViews)) {
        const EXPIRED = this.dateViews.in
          ? new Date(Math.min(new Date(this.dateViews.in), Date.now() + 864000000))
          : "1d";

        const PAYLOAD = {
          date_begin: this.removeTZ(this.dateViews.in),
          date_end: this.removeTZ(val)
        };

        const { host } = window.location
        let newHost = host;
        newHost = newHost.split('.')
        if(newHost.length >= 3) {
          newHost = newHost.slice(1)
        }

        newHost = newHost.join(".");

        const DOMAIN = `.${newHost}`

        VueCookies.set("calendar_dates", PAYLOAD, EXPIRED, null, DOMAIN);
      }
      eventsDispatcher('WIDGET_CHANGE_DATES', document)
    },
    date: {
      deep: true,
      handler(val) {
        this.dateViews = val;
      },
    },
    "date.in"(val) {
      if (!this.isPlatform) {
        this.$store.commit("search/setCheckIn", val);
      }
    },
    "date.out"(val) {
      if (!this.isPlatform) {
        this.$store.commit("search/setCheckOut", val);
      }
      if (val) {
        this.clickOutside();
      }
    },
    visibleCalendar(val) {
      if (!val && this.dateViews.in && !this.dateViews.out) {
        if (this.checkIn && this.checkOut) {
          this.dateViews.in = this.checkIn;
          this.dateViews.out = this.checkOut;
        } else {
          this.dateViews.in = this.date.in;
        }
      }
      if (val) {
        this.$nextTick(() => {
          this.$refs.calendar.$refs.baseModalRef.$el.scrollIntoView({
            block: "end",
            behavior: "smooth",
          });
        });
      }
    },
    locationData: {
      deep: true,
      handler() {
        if(this.dateViews && !this.dateViews.in && !this.dateViews.out && this.applyWatchLocation) {
          setTimeout(() => {
            this.checkInOpen()
          }, 100);
        }
      }
    }
  },
  computed: {
    activeIn() {
      return !!(
        this.visibleCalendar &&
        !this.dateViews.in &&
        !this.dateViews.out
      );
    },
    activeOut() {
      return !!(
        this.visibleCalendar &&
        this.dateViews.in &&
        !this.dateViews.out
      );
    },
    fillMode() {
      if (this.activeFieldIn) {
        return "in";
      } else if (this.activeFieldOut) {
        return "out";
      } else {
        return null;
      }
    },
    guestsCnt() {
      return this.guestsData.adults + this.guestsData.childrens.length;
    },
    isHasCookieGuests(){
      const cookieGuests = VueCookies.get("select_guests")
        ? VueCookies.get("select_guests") : null;

      if(cookieGuests && cookieGuests.guests && cookieGuests.guests.adults > 1 || cookieGuests.guests.childrens.length){
        return cookieGuests
      }
      return false
    },
    isHasCookieDate(){
      const cookieDates = VueCookies.get('calendar_dates')
      if(cookieDates && cookieDates.date_begin && cookieDates.date_end) {
        return cookieDates;
      }
      return false;
    },
    isHasRedirected(){
      const filtersPlatform = window.location.search
          ? window.location.search
          : null;
      if(filtersPlatform){
        if(filtersPlatform.includes('guests_adults') || filtersPlatform.includes('occupied') || filtersPlatform.includes('guests_childrens')){
          return true;
        }
        return false
      }
      return false
    }
  },
  methods: {
    isDates (dates) {
      return (dates.in instanceof Date && !isNaN(dates.in))
          || (dates.out instanceof Date && !isNaN(dates.out))
    },
    redirect(){
      // если есть выбронные даты или кол гостей больше 1 добавлет урл параметры и перезагружает страницу
      if(this.isPlatform && !this.isHasRedirected){
        if(this.isHasCookieGuests || this.isHasCookieDate){
          const occupied = {
            in: new Date( this.isHasCookieDate.date_begin),
            out: new Date( this.isHasCookieDate.date_end),
          }

          const { host } = window.location;

          let query = _.pickBy({
            occupied: this.getFormatOccupied(occupied),
            guests_adults: this.isHasCookieGuests ? this.isHasCookieGuests.guests.adults: null,
            guests_childrens: this.isHasCookieGuests.guests.childrens.join(",")
          });

          query = Object.entries(query)
            .map((el) => {
              return `${el[0]}=${el[1]}`;
            })
            .join("&");

          window.open(`//${host}/?${query}`, "_self");

        }
      }
    },
    closeSuggestions() {
      eventsDispatcher('WIDGET_MODAL_CLOSE', document)
      this.$refs.baseModalRefCity.close()
    },
    showSuggestions() {
      eventsDispatcher('WIDGET_MODAL_SHOW', document)
      this.$refs.baseModalRefCity.show()
    },
    showGuests(e) {
      if(e > 1) {
        this.viewGuests = true
      }
    },
    removeTZ(date) {
      date = new Date(date);
      let month = (date.getMonth() + 1).toString().padStart(2, 0),
        day = date.getDate().toString().padStart(2, 0),
        year = date.getFullYear();
      return year + "-" + month + "-" + day;
    },
    preselectCity(city) {
      this.locationData = city
      this.locationPreselect = city
    },
    visibilityChanged(isVisible, entry) {
      this.isFixed = !isVisible;
    },
    handleWindowResize() {
      let screenSize = null;
      if (window.innerWidth <= 768 || window.screen.width <= 768) {
        screenSize = "mobile";
      } else if (window.innerWidth > 768) {
        screenSize = "desktop";
      }

      this.viewPort = screenSize;

      let screenSizeFixed = null;
      if (window.innerWidth < 1280) {
        screenSizeFixed = true;
      } else if (window.innerWidth >= 1280) {
        screenSizeFixed = false;
      }

      this.viewPortFixed = screenSizeFixed;
    },
    clickGuests() {
      if (this.$ga) this.$ga.event("dates_sa", "click", "main_guests");
    },
    getFormatOccupied(date) {
      const format = (date) => {
        const month = (date.getMonth() + 1).toString().padStart(2, 0),
          day = date.getDate().toString().padStart(2, 0),
          year = date.getFullYear();
        return year + "-" + month + "-" + day;
      };
      return date.in && date.out
        ? format(date.in) + ";" + format(date.out)
        : null;
    },
    clickToMap(){
      this.isOpenMap = true;
      this.doSearch();
    },
    async doSearch(modal = null, btnGa) {
      if(this.guestsData.childrens?.includes(null)){
        this.$refs.guests.guestError();
        return false;
      }
      if (btnGa === "search") {
        if (this.viewPort === 'mobile' && window.ga) {
          window.ga('send', 'event', 'searchwidget_pl_m', 'click', 'see_prices');
        }
      } else if (btnGa === "calendar") {
        if (this.viewPort === 'mobile' && window.ga) {
          window.ga('send', 'event', 'searchwidget_pl_m', 'click', 'show_options');
        }
      }
      if (!this.locationData.title && !modal) {
        if (!this.$refs.baseSuggestions.focused) {
          this.$refs.baseSuggestions.$refs.input.focus();
        }
        this.$refs.baseSuggestions.focused = true;
      } else if (
        (!this.date.in || !this.date.out) &&
        !modal &&
        !this.isOpenMap
      ) {
        setTimeout(() => {
          this.checkInOpen();
        }, 100);
      } else {
        this.viewDate = false; // для того чтобы колендарь не открывался при нажатии найти
        this.isBtnDisabled = true;

        const occupied =
          this.date.in && this.date.out
            ? this.getFormatOccupied({
                in: this.date.in,
                out: this.date.out,
              })
            : null;

        const filtersPlatform = window.filters
          ? window.filters.getFilters()
          : {};

        // fix VUECLIENT-828 на платформе есть кейсы где id берется с платформы и он несовпадает с id из виджета
        const location = this.locationData.location || {
          id: this.locationData.id,
          type: this.locationData.type,
        };

        let query = _.pickBy({
          ...filtersPlatform,
          guests_adults: this.guestsData.adults,
          guests_childrens: this.guestsData.childrens.join(","),
          term: this.locationData.title,
          ...location
        });
        if(this.guestsData.pets && this.guestsData.pets.value){
          query.pets = true;
        }
        if(occupied){
          query.occupied = occupied;
        }

        if (modal) query[`modal-${modal}`] = 1;

        const { host } = window.location;
        let newHost = host;
        newHost = newHost.split(".");
        if (newHost.length >= 3) {
          newHost = newHost.slice(1);
        }
        newHost = newHost.join(".");

        if (this.isPlatform) {
          if(this.isOpenMap){
            query = Object.entries(query)
              .map((el) => {
                return `${el[0]}=${el[1]}`;
              })
              .join("&");
            window.open(`//${newHost}/front/searchapp/search?${query}#map`, "_self");
          } else {
            query = Object.entries(query)
              .map((el) => {
                return `${el[0]}=${el[1]}`;
              })
              .join("&");
            window.open(`//${newHost}/front/searchapp/search?${query}`, "_self");
          }
        } else {
          await this.$nextTick();

          this.$router
            .push({
              name: "Search",
              query,
            })
            .catch((err) => {});
        }
      }
    },
    handlerLocation(e) {
      this.locationData = e;
    },
    handlerGuests(e) {
      this.guestsData = e;
    },
    initialSuggestions() {
      if (this.locationInsert && this.locationInsert.title) {
        this.locationData = {
          bbox: null,
          count: null,
          location: {
            type: this.locationInsert.type ? this.locationInsert.type : null,
            id: this.locationInsert.id ? this.locationInsert.id : null,
          },
          point: null,
          title: this.locationInsert.title ? this.locationInsert.title : null,
          title_ext: null,
        };
      }
    },
    initialDatesInsert() {
      if (this.datesInsert && this.datesInsert.in && this.datesInsert.out) {
        this.$set(this.date, "in", this.datesInsert.in);
        this.$set(this.date, "out", this.datesInsert.out);
      } else {
        const cookieDates = VueCookies.get('calendar_dates')
        if (cookieDates) {
          const date_begin = new Date(cookieDates.date_begin);
          const date_end = new Date(cookieDates.date_end);
          if (
            !this.isDates({
              in: date_begin,
              out: date_end
            })
          ) {
            this.handleReset();
            return;
          }

          if (cookieDates.date_begin && cookieDates.date_end) {
            this.$set(this.date, "in", new Date(cookieDates.date_begin));
            this.$set(this.date, "out", new Date(cookieDates.date_end));
          }
        }
      }
    },
    clickOutside() {
      if(this.viewPort === 'desktop')
        this.$refs.calendar.close();
    },
    checkInOpen() {
      if (this.dateViews.in) {
        this.activeFieldOut = false;
        this.activeFieldIn = true;
      }
      this.$refs.calendar.show();
    },
    checkOutOpen() {
      if (this.dateViews.out) {
        this.activeFieldIn = false;
        this.activeFieldOut = true;
      }
      this.$refs.calendar.show();
    },
    format(date, short = false) {
      if (!(date instanceof Date)) return "";
      const day = date.toLocaleString("ru-RU", {
        day: "numeric",
      });
      const month = date
        .toLocaleString("ru-RU", {
          month: "short",
        })
        .slice(0, 3);
      const weekday = date.toLocaleString("ru-RU", {
        weekday: "short",
      });
      return `${day} ${month}${
        !short ? `, ${weekday}` : ""
      }`.toLocaleLowerCase();
    },
    handleReset() {
      VueCookies.remove("calendar_dates")

      const { host } = window.location
      let newHost = host;
      newHost = newHost.split('.')
      if(newHost.length >= 3) {
        newHost = newHost.slice(1)
      }

      newHost = newHost.join(".");

      const DOMAIN = `.${newHost}`

      VueCookies.remove(
        "calendar_dates",
        null,
        DOMAIN
      );
      this.activeFieldIn = false;
      this.activeFieldOut = false;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.sc-searchwidget_mobile {
  &.sc-searchwidget {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  .sc-searchwidget {
    &__suggestions {
      margin-bottom: 4px;
      &-modal {
        margin-bottom: 4px;
      }
    }
    &__occupied {
      margin-bottom: 4px;
      justify-content: space-between;
    }
    &__guest {
      // margin-bottom: 10px;
    }
    &__btn {
      margin-top: 10px;
      margin-left: 0 !important;
      width: 100%;
      padding: 10px 39px;

      &-no-guests {
        margin-top: 10px;
        height: 60px;
      }
    }
  }
}
.sc-searchwidget_desktop {
  &.sc-searchwidget {
    position: relative;
    z-index: 3;
    width: max-content;
    color: #444;

    display: grid;
    grid-template-areas: "suggestions . occupied . guest . btn";
    grid-template-columns: 342px 2px 248px 2px 260px 2px max-content;
    grid-template-rows: 60px;

    @media screen and (max-width: 1100px) {
      width: 100%;
      grid-template-columns: 342fr 2px 248fr 2px 248fr 2px 155px;

    }
  }
  .sc-searchwidget__btn {
    @media screen and (max-width: 1100px) {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
    }
  }
}
.sc-searchwidget {
  font-family: "Inter", sans-serif;
  font-size: 16px;

  label {
    line-height: 17px;
    font-size: 16px;
  }

  &__select-location {
    position: absolute;
    bottom: -25px;
    left: 0;
    z-index: 0;
    width: 100%;
    max-width: 990px;
    margin: 0 auto;
    padding: 6px 0 0 10px;
    display: flex;
    color: #fff;
    font-size: 14px;

    span {
      &.title {
        opacity: 0.7;
      }
      &.val {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transform: translateY(0);
    transition: transform 0.35s ease;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateY(-100%);
  }

  &-fixed {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);

    &__wrap {
      padding: 10px 20px;
      display: flex;
      overflow: auto;
    }

    &__button {
      cursor: pointer;
      margin-left: 5px;
      border: 1px solid #d8d8d8;
      border-radius: 3px;
      color: #000;
      background: #fff;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      min-height: 40px;
      font-size: 14px;

      &:first-child {
        margin-left: 0;
      }

      &_fill {
        border: 1px solid #000;
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  &__suggestions {
    grid-area: suggestions;
  }
  &__occupied {
    grid-area: occupied;

    display: flex;

    &_in {
      margin-right: 1px;
    }
    &_out {
      margin-left: 1px;
    }
    &_in,
    &_out {
      white-space: nowrap;
      background-color: white;
      width: calc(50% - 1px);
      padding: 0 12px;
      height: 60px;
      color: #717171;
      display: block;
      align-items: center;
      position: relative;
      border: 1px solid transparent;
      cursor: pointer;
      transition: border 0.3s;
      .icon-app-calendar {
        pointer-events: none;
        font-size: 20px;
        color: #dadada;
        margin-left: auto;
        box-shadow: -9px 0px 4px 2px #ffffff;
      }
      .title {
        transform: translate(0px, 0px) scale(1);
        transform-origin: 20px 0;
        transition: transform 0.15s, opacity 0.1s, padding-top 0.1s;
        margin-bottom: -15px;
        padding-top: 19px;
        &.active {
          transform: translate(-3px, -9px) scale(0.9);
          color: #717171;
          padding-top: 21px;
        }
      }
      &-active {
        border: 1px solid #f51449;
      }
      &-active-choice {
        .title {
          transform: translate(-3px, -9px) scale(0.9);
          color: #717171;
        }
      }
      &-fill {
        .date {
          color: #000;
        }
      }
    }
    &.sc-searchwidget__occupied-mobile {
      .sc-searchwidget__occupied {
        &_in {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          margin-right: 2px;
        }
        &_out {
          margin-left: 2px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
        &_in,
        &_out {
          width: calc(50% - 2px);
        }
      }
    }
  }
  .to-map{
    padding-bottom: calc(60px + env(safe-area-inset-bottom));
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .to-map__btn{
    width: 105px;
    height: 42px;
    border-radius: 36px;
    border: none;
    outline: none;
    color: #fff;
    background: #444;
    font-family: Inter;
    font-size: 14px;
  }
  .map-enter-active,
  .map-leave-active {
    transform: translateX(-50%) translateY(0%);
    transition: transform 0.35s ease;
  }
  .map-enter,
  .map-leave-to {
    transform: translateX(-50%) translateY(100px);
    transition: transform 0.35s ease;
  }
  .calendar {
    &-in,
    &-out {
      color: #444;
      position: relative;
      top: calc(100% + 4px);
      // left: 90px;
      .sc-datepicker-wrapper {
        margin-top: 0;
        transform: translateX(calc(-50% - 125px));
      }
    }
    &-out {
      left: 90px;
    }
  }
  &__guest {
    grid-area: guest;
  }
  &__btn {
    grid-area: btn;
    position: relative;
    margin-left: 10px;
    padding: 19px 39px;
    font-size: 16px;
    line-height: normal;
    border-color: #f51449;
    background: #f51449;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 3px;

    &:active,
    &:hover,
    &:focus {
      color: #fff;
      background: #f62c5c;
      border-color: #f51449;
    }
    &:active {
      background-image: none;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    &:disabled,
    &__disabled,
    &.disabled {
      pointer-events: none;
    }
    &.loading {
      opacity: 1;
    }
  }
  &__suggestions-modal {
    background: #ffffff;
    border-radius: 3px;
    height: 56px;
    color: #717171;

    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 2px;

    position: relative;
    z-index: 1;
    width: 100%;
    &::after {
      content: "";
      background-image: url("./assets/maps-bubble-icon.svg");
      background-repeat: no-repeat;
      display: block;
      width: 14px;
      height: 18px;
      margin-left: auto;
    }
    &__fill {
      color: #444444;
    }
  }
}
</style>
